import { ElMessage, ElMessageBox } from 'element-plus';
import myAxiosPDF from '../../../utils/axios/index_v1';
import CustomsDocsClass from './customsdocsClass';
import QueryQrCodeClass from '../../../utils/utilsClass/queryQrCodeClass';
import ConstClass from '../../../store/class/constClass'; // 用户等级

// 注意：函数名称不要改动，与对象绑定，否则会出错
export default class OperationsBtnClass extends CustomsDocsClass {
  /**
   *
   * @param {*} customsDocsDataBus 共享数据
   *
   * @param {*} setFilterOfContainer 访问权限代码的数据
   * @param {*} maskDivState 弹出详情的共享数据
   */
  constructor(customsDocsDataBus, setFilterOfContainer, maskDivState, storeDatas) {
    super(customsDocsDataBus, setFilterOfContainer);
    this.maskDivState = maskDivState;
    this.storeDatas = storeDatas;

    this.queryQrCodeClass = new QueryQrCodeClass(this.setFilterOfContainer, this.maskDivState, this.storeDatas);
  }

  /**
   * 用户当前状态值处理不同函数
   * @param {string} eventName 详情、复制、编辑等调用函数名称
   * @param {*} options 当前行操作数据对象
   * @param {*} router  路由
   */
  getFunc(eventName, options, router) {
    this.customsDocsDataBus.expandRowKey = [options.id]; // 下拉展示id
    if (this[eventName]) {
      this[eventName](options, router);
    } else {
      throw new Error('对象错误');
    }
  }

  // 详情点击事件
  detailFunc = (options) => {
    // 判断如果未提交的数据是否能加载图片（管理员）
    if (options.status === '1') {
      ElMessageBox.alert('当前数据未提交，请提交后再展示文件');
      return;
    }
    // 点击后将值传递给dialogDetail.vue处理
    Object.assign(this.maskDivState, {
      status: true,
      options,
    });
  };

  // 复制点击事件
  copyFunc = (options, router) => {
    if (options.status < '2') {
      ElMessageBox.alert('当前数据未提交，提交后再复制');
      return;
    }
    this.navigatToCreateCustoms(this.storeDatas, router, 'copy', options.id);
  };

  // 编辑点击事件
  editFunc = (options, router) => {
    this.navigatToCreateCustoms(this.storeDatas, router, options.fromBaseData, options.id);
  };

  // 删除点击事件
  deleteFunc = (options) => {
    let prdName = options.regexpNodeList.productName;
    if (prdName.length > 10) {
      prdName = `${prdName.substr(0, 10)}·····`;
    }
    // 正在编辑
    ElMessageBox.confirm(`确定删除合同号【${options.contactNum || '-'}】,品名为【${prdName}】的数据？`, {
      title: '警告',
      type: 'warning',
      showClose: false,
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      closeOnClickModal: false, // 点击蒙层取消
      closeOnPressEscape: false, // 禁止按ESC
    })
      .then((conf) => {
        if (conf === 'confirm') {
          // 删除
          this.optUuid = options.id; // 操作产品id
          this.optDatabase = options.fromBaseData; // 数据库存放位置
          this.customsDocsAxiosFunc('deleteFunc');
          this.customsDocsDataBus.expandRowKey = []; // 清空下拉展示id
        }
      })
      .catch((err) => {
        if (err === 'cancel') {
          // 取消
          ElMessage.success('删除取消');
        }
      });
  };

  // 支付点击事件
  payFunc = (options) => {
    Object.assign(this.maskDivState, {
      updateDataFunc: (idStr) => { // 支付成功后更新本地数据
        const { tableDatas } = this.customsDocsDataBus;
        tableDatas.forEach((obj) => {
          if (obj.id === idStr) {
            obj.optionsStatus = (Number(obj.optionsStatus) + 1).toString();
            obj.status = (Number(obj.status) + 1).toString();
            this.setFilterOfContainer.loadOnPayLineState = true;// 让在线支付重新加载数据同步
          }
        });
      },
    });
    this.queryQrCodeClass.payFuncByQC(options);
  };

  // 电子委托授权事件
  signFun = (options) => {
    if (options.status < '3') {
      ElMessageBox.alert('当前数据未提交，提交后再委托授权');
      return;
    }
    this.customsDocsDataBus.dialogFormVisible = true;
    const { dialogFormData } = this.customsDocsDataBus;
    dialogFormData.uuid = options.id; // 当前操作的uuid
  };

  // 下载
  downLoadFunc = async (options) => {
    // console.log('点击了下载', options.id);
    // 判断如果未提交的数据是否能加载图片（管理员）
    if (options.status === '1') {
      ElMessageBox.alert('当前数据未提交，提交后再下载文件');
      return;
    }
    let p;
    // const stateInfo = false;// 默认下载空白报关资料
    if (this.setFilterOfContainer.visitAuth < ConstClass.USER_ROOT) { // 普通会员
      await ElMessageBox.confirm('正在准备下载报关资料，所下载完成后签章后即可使用', {
        title: '提示',
        cancelButtonText: '取消',
        confirmButtonText: '下载',
        distinguishCancelAndClose: true, // 点击x按钮与遮罩关闭响应（close）与取消不同（cancel)
        closeOnPressEscape: false, // ESC 键关闭
      }).then((r) => {
        if (r === 'confirm') {
          p = this.getZipfiles(options.id, false, '', '');// 下载空白报关资料
        }
      }).catch((state) => {
        if (state === 'cancel') {
          ElMessage.success('下载取消');
        }
      });
    } else { // 管理员
      await ElMessageBox.confirm('正在准备下载报关资料，所下载资料是否需要添加电子印章', {
        title: '提示',
        cancelButtonText: '下载空白资料',
        confirmButtonText: '添加印章',
        distinguishCancelAndClose: true, // 点击x按钮与遮罩关闭响应（close）与取消不同（cancel)
        closeOnPressEscape: false, // ESC 键关闭
      }).then((r) => {
        if (r === 'confirm') {
          p = this.getZipfiles(options.id, true, 'JHR', '');// 下载JHR签章资料
        }
      }).catch((state) => {
        if (state === 'cancel') {
          p = this.getZipfiles(options.id, false, '', '');// 下载空白资料
        }
      });
    }

    if (p !== undefined) {
      p.then((res) => {
        if (res.status === 200) {
          const elemtA = document.createElement('a');
          elemtA.style.display = 'none';
          // 获取文件名称
          const fileName = res.headers['content-disposition'].match(/=(.*)/)[1];
          elemtA.download = `报关资料${fileName}`;
          elemtA.href = window.URL.createObjectURL(new Blob([res.data], { type: 'application/zip' }));
          document.body.appendChild(elemtA);
          elemtA.click();
          document.body.removeChild(elemtA);
          URL.revokeObjectURL(elemtA.href);
        }
      });
    }
  };

  /**
   * @method getZipfiles 获取pdf压缩文件
   * @param {string} id 采样数据id
   * @param {bool} isShowChop 是否在pdf中展示印章
   * @param {string} chopModel 印章名称["test","JHR"]
   * @param {string} token 鉴权
   *
   */
  getZipfiles = (id, isShowChop, chopModel, token) => new Promise((resolve, reject) => {
    myAxiosPDF()
      .initAxios({
        method: 'POST',
        url: '/getpdf2image/pdf',
        responseType: 'arraybuffer',
        data: {
          id, // 操作数据id
          chop_model: chopModel, // 印章名称
          is_show_chop: isShowChop, // 是否显示印章
          token,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
