export default class SelectoptionsFormClass {
  constructor() {
    this.resultOptions = {}; // 用于存放查询结果数据值
    this.backupOptions = {};// 用于存放备份datas中的数据
    this.setTimeoutObj = {}; // 延时器
    this.opOriginDatas = {};// 操作源数据
    this.clkIdx = null; // 如果点击了，点击后的值是（null没有点击,为数值时说明被点击了)
    this.isHidenOptions = {};// 判断当前是隐藏了下拉选择项[true:展示,false:隐藏]
  }

  // 设置下拉选择自定义输入
  /**
   *
   * @param {object} queryFormData  表单原数据
   * @param {Array} activeDatas 展示下拉列表数据
   * @param {string} keyWord 下拉输入时内容
   * @param {string} opNodeStr 操作节点
   * @param {string} tempArg 备份参数 （用途：activeDatas子节点可能不是opNodeStr）
   */
  getQueryFunc = (queryFormData, activeDatas, keyWord, opNodeStr, tempArg) => {
    clearTimeout(this.setTimeoutObj[opNodeStr]);
    this.setTimeoutObj[opNodeStr] = setTimeout(() => {
      let i = 0;// 记录取到10条记录就返回值
      const tempAry = [];
      try {
        this.opOriginDatas[opNodeStr].forEach((item) => {
          if (item.tagName.toLocaleUpperCase().includes(keyWord.toLocaleUpperCase())) {
            i += 1;
            tempAry.push({ ...item });
            if (i === 20) {
              throw Error('exitFor');
            }
          }
        });
      } catch (error) {
        if (error === 'exitFor') {
          console.error('error:', error);
        }
      }

      activeDatas[tempArg || opNodeStr] = [...tempAry];
      if (tempAry.length > 0 && keyWord !== '') {
        // 查询到有值
        this.resultOptions[opNodeStr] = tempAry[0].value;
      } else {
        // 无值则将临时变量置空
        this.resultOptions[opNodeStr] = '';
      }

      if (this.isHidenOptions[opNodeStr] === false) {
        // 说明延时了，操作至另一个select，未跟上
        this.setNodeResult(queryFormData, activeDatas, opNodeStr, tempArg);
      }
    }, 400);
  };

  // 下拉框变换时动作
  /**
   *
   * @param {bool} e 下拉框是否展示或隐藏
   * @param {string} opNodeStr 操作节点
   * @param {object} queryFormData 表单数据
   * @param {object} activeDatas 展示下拉列表的数据
   * @param {array} opOriginDatas options的源数据
   * @param {string} tempArg options的源数据
   */
  changeOptionsFunc = (e, opNodeStr, queryFormData, activeDatas, opOriginDatas, tempArg) => {
    if (e) { // 显示下拉框
      this.isHidenOptions[opNodeStr] = true;// 展示下拉框
      this.resultOptions[opNodeStr] = '';
      this.setTimeoutObj[opNodeStr] = null;
      this.backupOptions[opNodeStr] = queryFormData[opNodeStr];// 备份表单原数据
      this.opOriginDatas[opNodeStr] = opOriginDatas;// 备份原数据

      queryFormData[opNodeStr] = '';
    } else { // 隐藏下拉框
      this.isHidenOptions[opNodeStr] = false;// 隐藏了下拉框
      this.setNodeResult(queryFormData, activeDatas, opNodeStr, tempArg);
    }
  };

  // setNodeResult 解决setTimeOut延时处理更改值的问题
  setNodeResult = (queryFormData, activeDatas, opNodeStr, tempArg) => {
    const res = this.resultOptions[opNodeStr];
    // 有搜索结果则使用，无则使用原数据 并清空索引结果同时复原
    if (this.clkIdx === null) {
      const resExists = (res !== '')// 自定义结果有值
        && (this.clkIdx === null); // 不是点击事件
      if (tempArg === undefined) {
        // 查询制作文件需要判断下拉options是否可编辑
        // 操作状态为可编辑
        queryFormData[opNodeStr] = (resExists && !this.activeDatas.status[0].disabled) ? res : this.backupOptions[opNodeStr];
        activeDatas[tempArg || opNodeStr] = (resExists && !this.activeDatas.status[0].disabled) ? res : this.backupOptions[opNodeStr];
      } else { // 录入报关信息页
        queryFormData[opNodeStr] = (resExists) ? res : this.backupOptions[opNodeStr];
        activeDatas[tempArg || opNodeStr] = (resExists) ? res : this.backupOptions[opNodeStr];
      }
    }
    // 初始数据
    this.resultOptions[opNodeStr] = '';
    this.clkIdx = null;// 复原点击事件值
    activeDatas[tempArg || opNodeStr] = [...this.opOriginDatas[opNodeStr]];// 复原
  }

  /** 下拉框点击结果集
  * @param {String} e 点击的结果value
  */
  rangeOptionsFunc = (e, opNodeStr) => {
    this.clkIdx = e;// 标记点击事件
    if (e !== '') {
      this.resultOptions[opNodeStr] = e;
    }
  };
}
