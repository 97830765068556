// navgatTo.js封装按钮跳转页面
import { useRouter } from 'vue-router';
import {
  reactive,
  inject,
} from 'vue';
import dialogs from '@/utils/dialog';

export default function () {
  const router = useRouter();
  const subMenus = reactive(inject('subMenusBus'));// 所有子菜单项
  const currentPage = reactive(inject('currentShowPageBus'));// 当前展示页
  const tabsData = reactive(inject('tabsDataBus'));// tabs数据
  const datas = reactive({
  });
  const provideElments = inject('provideElments');// 获取事件总线数据
  const mythods = reactive({
    /**
       * 点击跳转事件
         * @param {String} navgPath 要跳转的路径
         * @param {Object} options 参数
         * @param {String} navType 跳转的类型"replace" || "push"
         */
    navigatTo: ((navgPath, id, navgType) => {
      if (navgType === 'replace') {
        router.replace({ path: navgPath }).catch((err) => {
          console.error(err);
        });
      } else { // 不传参数为push
        router.push({ path: navgPath, query: {} }).catch((err) => {
          console.error(err);
        });
      }
      provideElments.createcustoms.ids = id;// 全局事件总线cstmDocsIdsBus添加id属性
    }),
    navigatToAndClose: ((navgPath, id, navgType) => {
      // 判断tabsData内是否有当前页
      if (tabsData[navgPath]) {
        // tab中存在，已经打开. 弹出消息提示框
        dialogs.diaLog(
          '提示', `【${subMenus[navgPath].tagName}】页面正在编辑，是否要替换为当前数据编辑模式`,
          { cancelMessage: `正在返回【${subMenus[currentPage.name].tagName}】` },
        ).then((res) => {
          if (res === 'confirm') {
            // 执行跳转
            mythods.navigatTo(navgPath, id, navgType);
          }
        }).catch(() => {
          // 点击了取消键，需返回至跳转页
        });
      } else {
        // tab栏中没有[录入报关信息]栏，跳转
        mythods.navigatTo(navgPath, id, navgType);
      }
    }),
  });
  return {
    datas,
    mythods,
  };
}
