import { ElLoading, ElMessage, ElMessageBox } from 'element-plus';
import { nextTick } from 'vue';
import myAxios from '../../../utils/axios/index_v2';
import country from '../../../configs/country'; // 目的地国家数组
import stepStatus from '../../../configs/stepStatusAry';
// 当前进度代码
export default class TableFormClass {
  constructor(tableFromRef, customsDocsDataBus) {
    this.tableFromRef = tableFromRef;
    this.customsDocsDataBus = customsDocsDataBus;
    this.setTimeoutObj = null;
    this.tempCountry = this.initTransformFunc(country); // 目的国对象
    this.tempStaus = this.initTransformFunc(stepStatus.stepStatusAry); // 当前进度对象
  }

  // initDestCountryfunc 解析目的地数组成对象
  initTransformFunc = (openAry) => {
    const newObj = {};
    openAry.forEach((obj) => {
      if (obj.value !== '0') {
        // 如果是当前进度值0为全部状态
        newObj[obj.value] = obj.label;
      }
    });
    return newObj;
  };

  // transFormDestCountry 将城市代码转换成中文展示
  transFormDestCountry = (countryCode) => this.tempCountry[countryCode];

  // transFormDestStatus 将状态代码状态转换成中文展示
  transFormDestStatus = (statusCode) => this.tempStaus[statusCode];

  // transFormProductName 修截产品名称过长
  transFormProductName = (productName) => {
    const maxShowLen = 30;
    if (productName.length > maxShowLen) {
      return `${productName.substr(0, maxShowLen)}·····`;
    }
    // 正常显示
    return productName;
  };

  // 行点击事件
  rowClickFun = (row) => {
    //   this.tableFromRef.value.$refs.bodyWrapper.scrollTop = this.customsDocsDataBus.loadMoreSubPage.backupScrollTop;
    // 先判断expandRowKey中是否有id存在
    // 存在：说明已点开，id相等，关闭，反之清空expandRowkey,添加新id
    // 不存在：添加新id
    if (
      this.customsDocsDataBus.expandRowKey.length
      && (this.customsDocsDataBus.expandRowKey[0] === row.id)
    ) {
      this.customsDocsDataBus.expandRowKey = [];
    } else {
      this.customsDocsDataBus.expandRowKey = [];
      this.customsDocsDataBus.expandRowKey.push(row.id);
    }
  };

  // addListenScrollFunc 添加table's scroll监听事件
  addListenScrollFunc = () => {
    try {
      const { bodyWrapper } = this.tableFromRef.value.$refs;
      bodyWrapper.addEventListener('scroll', this.scrollOptions);
    } catch (error) {
      ElMessage.error('操作失败');
    }
  };

  // removeListenScrollFunc 移除scroll事件
  removeListenScrollFunc = () => {
    try {
      const { bodyWrapper } = this.tableFromRef.value.$refs;
      bodyWrapper.removeEventListener('scroll', this.scrollOptions, false);
    } catch (error) {
      ElMessage.error('操作失败');
    }
  };

  // scrollOptions 滚动时事件
  scrollOptions = () => {
    try {
      const { bodyWrapper } = this.tableFromRef.value.$refs;
      const {
        clientHeight, // 显示区域高度
        scrollTop, // 卷入隐藏区高度
        scrollHeight, //  总高度
      } = bodyWrapper;
      const { loadMoreSubPage } = this.customsDocsDataBus;
      const { overLimitDistance, distance } = loadMoreSubPage;
      loadMoreSubPage.backupScrollTop = scrollTop;
      if (scrollTop > 0 && scrollHeight - clientHeight - scrollTop <= distance) {
        // 延迟向后台加载
        if (!overLimitDistance) {
          if (this.setTimeoutObj !== null) {
            clearTimeout(this.setTimeoutObj);
          }
          this.setTimeoutObj = setTimeout(() => {
            // 已滑动到底，且只加载一次标识
            loadMoreSubPage.overLimitDistance = true;
            this.loadMoreDatas(); // 加载分页数据
          }, 50);
        }
      } else if (overLimitDistance) {
        loadMoreSubPage.overLimitDistance = false;
      }
      // 未滑动到底
    } catch (error) {
      ElMessage.error('操作失败', error);
    }
  };

  // loadMoreDatas 向服务器加载分页数据
  loadMoreDatas = () => {
    // 服务器返回没有更多数据更新loading为false

    const { tableDatas, tableData, loadMoreSubPage } = this.customsDocsDataBus;
    const tbL = tableData.length;

    if (tbL >= tableDatas.length) {
      // 加载完成
      this.loadStatus('无更多数据!');
    } else {
      // 任有数据
      this.loadStatus('数据加载中...');
      const load = ElLoading.service({
        fullscreen: true,
        lock: true,
        text: '数据加载中...',
        background: 'rgba(0, 0, 0, 0.5)',
      });

      const spliceAry = tableDatas.slice(tbL, tbL + loadMoreSubPage.rowNum);

      this.customsDocsDataBus.tableData.push(...spliceAry);
      loadMoreSubPage.loading = false;

      load.close();
    }
  };

  // loadStatus 分页数据加载提示函数
  loadStatus = (msg = '数据加载中...') => {
    if (!['数据加载中...', '无更多数据!'].includes(msg)) {
      ElMessage.error('msg信息不符合');
      return;
    }
    const { loadMoreSubPage } = this.customsDocsDataBus;
    loadMoreSubPage.loading = true;
    loadMoreSubPage.loadingText = msg;
  };

  /** *******  电子授权委托 ******** */

  // 将转换为大写字母
  toUpwordFunc = (value, name) => {
    const { dialogData } = this.customsDocsDataBus.dialogFormData;
    const l = dialogData.length;
    dialogData[l - 1][name] = value.toUpperCase();
  };

  /**
   * 添加或删除电子授权委托行事件
   * @param {index} rowIndex  行号
   * @param {bool} handleCode 操作事件【true:移除，false：添加】
   * @param {object} authorizeRef ref
   * @param {object} inputErrorMsg 错误提示信息
   */
  operationRowFunc = (rowIndex, handleCode, authorizeRef, inputErrorMsg) => {
    const { dialogData } = this.customsDocsDataBus.dialogFormData;
    if (handleCode) {
      // 移除数据
      ElMessageBox.confirm(`确定要删除【${dialogData[rowIndex].companyName}】数据`, {
        title: '提示',
        cancelButtonText: '取消',
        confirmButtonText: '确定',
      })
        .then((res) => {
          // 确定
          if (res === 'confirm') {
            dialogData.splice(rowIndex, 1);
          }
        })
        .catch((res) => {
          // 取消
          if (res !== 'cancel') {
            ElMessage.error('取消失败，请重试');
          }
        });
    } else {
      // 添加
      authorizeRef.validate((bl) => {
        // 判断输入数据是否正确
        if (bl) {
          const dLen = dialogData.length;
          // 存放一个临时数据
          const companyNameAry = [];
          const companyCodeAry = [];
          dialogData.forEach((item, idx) => {
            if (idx < dLen - 1) {
              // 最后一个值不添加
              companyCodeAry.push(item.companyCode);
              companyNameAry.push(item.companyName);
            }
          });

          let goback = false;
          // 筛选重复数据
          if (companyNameAry.includes(dialogData[dLen - 1].companyName)) {
            nextTick(() => {
              inputErrorMsg.companyName = '企业名称重复';
            });
            goback = true;
          }
          if (companyCodeAry.includes(dialogData[dLen - 1].companyCode)) {
            nextTick(() => {
              inputErrorMsg.companyCode = '企业海关注册编码重复';
            });
            goback = true;
          }
          if (goback) {
            return;
          }

          // 添加数据
          dialogData[dLen - 1].optionsCode = true;
          dialogData.push({
            companyName: '',
            companyCode: '',
            optionsCode: false, // true:移除，false：添加
          });

          // 卷到最底部
          nextTick(() => {
            const nodeList = authorizeRef.$el.querySelectorAll('.el-table__body-wrapper')[0];
            nodeList.scrollTop = 70 * (dLen - 1);
          });
        }
      });
    }
  };

  // // 电子授权关闭之前事件
  // closeBeforeFunc=(done) => {

  //   done();
  // }

  closeFunc = (authorizeRef) => {
    const { dialogFormData } = this.customsDocsDataBus;
    // 清空数据
    dialogFormData.dialogData = [
      {
        companyName: '',
        companyCode: '',
        optionsCode: false, // true:移除，false：添加
      },
    ];

    nextTick(() => {
      // 清除错误提示
      authorizeRef.clearValidate();
    });
  };

  /**
   * 电子授权委托提交事件
   * @param {object} data
   */
  submitAuthorizeFunc = (data, authorizeRef) => {
    const { dialogFormData } = this.customsDocsDataBus;
    // 最后一个对象是否有值，有则校验，无则判断数据对象是否大于1，
    const aryLen = dialogFormData.dialogData.length;
    const lastObj = dialogFormData.dialogData[aryLen - 1];
    if (lastObj.companyCode !== '' || lastObj.companyName !== '') {
      // 编辑框中有值
      //  console.log('编辑框中有值');
      // 校验是否有误
      authorizeRef.validate((bl) => {
        if (bl) {
          // 无误
          this.submitAuthorize(this.washSubmitData(dialogFormData.dialogData)); // 提交数据
        }
      });
    } else if (aryLen > 1) {
      // 编辑框中没有值，数据中有多条数据
      //  console.log('编辑框中没有值，数据中有多条数据');
      const tempObj = [...dialogFormData.dialogData]; // 复制一个新变量
      this.submitAuthorize(this.washSubmitData(tempObj.splice(0, aryLen - 1)));
    } else {
      // 编辑框中没有值，且只一条待编辑数据
      //  console.log('编辑框中没有值，且只一条待编辑数据', lastObj);
      authorizeRef.validate((bl) => {
        if (bl) {
          this.submitAuthorize(lastObj);
        }
      });
    }
  };

  /**
   * 洗数据
   * @param {Array} dataAry 数组对象，根据编辑框中的内容与否截取dialogData中的数据
   * @returns 返回新数据给到后台
   */
  washSubmitData = (dataAry) => {
    const newSubmitData = [];
    dataAry.forEach((item) => {
      newSubmitData.push({ companyName: item.companyName, companyCode: item.companyCode });
    });
    return newSubmitData;
  };

  /**
   * 将数据传递给后台，并持久化
   * @param {Array} authorizeData 要提交的数据,格式类型[{companyName:xxxxx,companyCode:xxxxx},.....]
   */
  submitAuthorize = (authorizeData) => {
    const { uuid } = this.customsDocsDataBus.dialogFormData;
    myAxios()
      .initAxios({
        method: 'POST',
        url: '/customs_authorize',
        Headers: { Accept: 'application/json; charset=utf-8' },
        data: {
          // 查询数据
          uuid,
          authorizeData,
        },
      })
      .then((res) => {
        if (res.code === 200) {
          // 写入成功，修改当前进度数据
          const { tableDatas } = this.customsDocsDataBus;
          tableDatas.forEach((item, idx) => {
            if (item.id === uuid) {
              // 修改展示数据变成已支付状态,及添加委托授权公司信息（避免向服务器获取数据)
              tableDatas[idx].optionsStatus = '6';
              tableDatas[idx].status = '6';
              tableDatas[idx].authorizeLists = authorizeData;
            }
          });
          ElMessage.success(res.message);
          this.customsDocsDataBus.dialogFormVisible = false; // 关闭换提交数据
        }
      })
      .catch((err) => {
        if (err.code === 3001) {
          // 写入失败
          ElMessage.warning('操作失败');
        }
        this.customsDocsDataBus.dialogFormVisible = false; // 关闭换提交数据
      });
  };
}
