import {
  ElMessage,
  ElMessageBox,
} from 'element-plus';
import stepStatus from '../../../configs/stepStatusAry';
import TabClkClass from '../../tabslist/class/tabClkClass';
import CustomsDocsClass from './customsdocsClass';
import SelectoptionsFormClass from '../../../utils/selectOfqueryFormClass';
import QueryAuthClass from '../../../store/class/queryAuthClass';

export default class QueryFormClass extends SelectoptionsFormClass {
  #router

  /**
   *
   * @param {*} customsDocsDataBus 共享表单数据
   * @param {*} activeDatas 展示执行响应数据
   * @param {*} storeDatas 路由中共享数据
   * @param {*} router 路由
   * @param {*} setFilterOfContainer 登陆的数据
   */
  constructor(customsDocsDataBus, activeDatas, storeDatas, router, setFilterOfContainer) {
    super();
    this.customsDocsDataBus = customsDocsDataBus;
    this.#router = router;// 路由器
    this.storeDatas = storeDatas;// 路由相关数据
    this.activeDatas = activeDatas;// 响应数据
    this.stepStatus = stepStatus.stepStatusAry;
    this.setFilterOfContainer = setFilterOfContainer;
    this.tabClkClass = new TabClkClass(this.storeDatas, null, this.#router);
    this.customsDocsClass = new CustomsDocsClass(this.customsDocsDataBus, setFilterOfContainer);
    this.inputval = '';// 输入值
    this.queryAuthClass = new QueryAuthClass(this.storeDatas, this.setFilterOfContainer, this.#router);// 鉴权
  }

  // 查询函数
  searchSubmitFunc = (queryFormRef) => {
    if (!this.setFilterOfContainer.isLogin) {
      // 未登陆情况下鉴权
      this.queryAuthClass.queryAuthToken();
    }
    // 校验数据
    queryFormRef.validate((volid) => {
      if (volid) {
        try {
          // 加载时向后台服务器加载数据
          this.customsDocsClass.customsDocsAxiosFunc('initQueryFunc');
        } catch (error) {
          ElMessage.error(error.message);
        }
      } else {
        // 查询制作文件输入查询内容有校验错误，跳转至当前tab，展示错误信息并提示
        this.tabClkClass.tabDatas = this.storeDatas.tabsDataBus['/customsdocs'];
        this.tabClkClass.tabClkFunc();
        ElMessage.error('请输入正确的查询内容');
      }
    });
  }

  // 清空按钮
  resetContentFunc = (queryFormRef) => {
    // 提示框
    ElMessageBox.confirm('确定重置清空当前输入内容？', {
      type: 'warning',
      showClose: false,
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      closeOnClickModal: false, // 点击蒙层取消
      closeOnPressEscape: false, // 禁止按ESC
    }).then((res) => {
      // 确定
      if (res === 'confirm') {
        queryFormRef.resetFields();
      }
    }).catch(() => {
      // 取消
    });
  }

  // 设置下单时间选择范围（3年内）
  disabledDateFunc = (time) => {
    // time.getTime() < new Date().getTime() - 3 * 365 * 24 * 3600 * 1000 || time.getTime() > Date.now()
    const dateNow = new Date();
    const bl = time.getTime() > dateNow.setFullYear(dateNow.getFullYear() - 3) && time.getTime() < new Date().valueOf();

    return !bl;
  }

  // 下单时间失去焦点
  blurFun = () => {
    // 不能大于3个月时间,范围在3年内数据,不能超过现在
    const maxYear = 3;
    const maxMounth = 3;
    this.customsDocsDataBus.queryFormData[1] = new Date(Number(this.customsDocsDataBus.queryFormData[1])).setHours(23, 59, 59, 59); // 设置成23:59:59分
    const { orderDate } = this.customsDocsDataBus.queryFormData;
    const startDate = new Date(Number(orderDate[0])); // 开始时间
    const endDate = Number(orderDate[1]); // 结束时间
    const formatDate = new Date().setHours(23, 59, 59, 59);// 当前时间
    const overMaxDate = Number(orderDate[1]) > formatDate || Number(orderDate[0]) > formatDate; // 大于当前时间
    const overMounth = startDate.setMonth(startDate.getMonth() + 3) < endDate;// 最大限制90天
    const setBestYear = new Date(formatDate).setFullYear(new Date(formatDate).getFullYear() - 3);// 设置成3年前的时间
    const overYear = Number(orderDate[0]) < setBestYear || Number(orderDate[1]) < setBestYear;// 年限不超3年

    if (overMounth || overYear || overMaxDate) {
      ElMessageBox.alert(overYear ? `请选择近${maxYear}年内数据!` : `请勿超出当日数据或选择${maxMounth}个月范围数据!`, {
        title: '提示',
        type: 'warning',
        showClose: false,
        confirmButtonText: '确定',
        closeOnClickModal: false, // 点击蒙层取消
        closeOnPressEscape: false, // 禁止按ESC
      }).then((res) => {
        if (res === 'confirm') {
          // 30天时间
          const resetDate = new Date();
          this.customsDocsDataBus.queryFormData.orderDate = [
            new Date(resetDate.getFullYear(), resetDate.getMonth() - 1, resetDate.getDate(), 0, 0, 0).valueOf().toString(),
            new Date(resetDate.getFullYear(), resetDate.getMonth(), resetDate.getDate(), 23, 59, 59).valueOf().toString(),
          ];
        }
      });
    }
  }

  // newOrderFunc 创建订单
  newOrderFunc = () => {
    this.customsDocsClass.navigatToCreateCustoms(this.storeDatas, this.#router, '');
  }

  // optionChangeFuncs 下拉点击更新数据
  optionChangeFuncs = (e, opNodeStr) => {
    this.rangeOptionsFunc(e, opNodeStr);
  }

  // vsbChangeOptions 下拉框展示
  vsbChangeOptions = (e, opNodeStr, opOriginDatas) => {
    if (e) {
      this.changeCNtoEN(opNodeStr);
    }
    this.changeOptionsFunc(e, opNodeStr, this.customsDocsDataBus.queryFormData, this.activeDatas, opOriginDatas);
  }

  inputFunc = (e, opNodeStr) => {
    this.getQueryFunc(this.customsDocsDataBus.queryFormData, this.activeDatas, this.inputval, opNodeStr);
  }

  changeCNtoEN = (nodeStr) => {
    const that = this;
    const inputVal = document.querySelector(`#${nodeStr}`);
    inputVal.oninput = function () {
      that.inputval = inputVal.value;
    };
  }

  // setPickerDefaultDate 设置下单时间初始值
  setPickerDefaultDate = () => {
    const startDate = new Date(new Date().getFullYear(), new Date().getMonth() - 1, new Date().getDate(), 0, 0, 0).valueOf();
    const endDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59).valueOf();
    this.customsDocsDataBus.queryFormData.orderDate[0] = `${startDate}`;
    this.customsDocsDataBus.queryFormData.orderDate[1] = `${endDate}`;
  }

  // setStatusFunc 设置状态下拉列表可编辑状态
  setStatusFunc = (isLogin = false) => {
    const newObj = [];
    this.stepStatus.forEach((obj, idx) => {
      if (isLogin) {
        Object.assign(obj, { disabled: false });
      } else if (['全部', '待提交'].includes(obj.label)) {
        Object.assign(obj, { disabled: false });
      } else {
        Object.assign(obj, { disabled: true });
      }
      newObj[idx] = obj;
    });
    this.activeDatas.status = [...newObj];
    this.customsDocsDataBus.queryFormData.status = '0';
  }
}
