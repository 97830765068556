<template>
  <div class="tableform">
    <el-table ref="tableFromRef" :data="customsDocsDataBus.tableData" style="width: 100%" row-key="id" empty-text="无数据"
      :expand-row-keys="customsDocsDataBus.expandRowKey" height="380"
      :default-sort="{ prop: 'date', order: 'descending' }" @row-click="tableFormClass.rowClickFun" border stripe>
      <el-table-column type="expand">
        <template v-slot="props">
          <el-form label-position="right" label-suffix=":" inline class="demo-table-expand">
            <el-form-item label="下单时间">
              <span>{{ props.row.date }}</span>
            </el-form-item>
            <el-form-item label="总净重">
              <span>{{ props.row.regexpNodeList.netWt }} 千克</span>
            </el-form-item>
            <el-form-item label="商品名称">
              <span>{{ tableFormClass.transFormProductName(props.row.regexpNodeList.productName) }}</span>
            </el-form-item>
            <el-form-item label="总毛量">
              <span>{{ props.row.regexpNodeList.grossWt }} 千克</span>
            </el-form-item>
            <el-form-item label="总箱数">
              <span>{{ props.row.regexpNodeList.ctns }}</span>
            </el-form-item>
            <el-form-item label="总金额">
              <span>{{ props.row.regexpNodeList.totalAmount }} 美元</span>
            </el-form-item>
            <el-form-item label="收货人">
              <span>{{ props.row.consignee }}</span>
            </el-form-item>
            <el-form v-show="props.row.failedReason && failedReasonCode.parseFailedCode(props.row.failedReason)">
              <el-form-item label="审核不通过原因">
                <span style="color:red">{{ failedReasonCode.parseFailedCode(props.row.failedReason) }}</span>
              </el-form-item>
            </el-form>
            <el-form v-show="props.row.authorizeLists">
              <el-form-item :label="`电子委托授权${idx + 1}`" v-for="(item, idx) in props.row.authorizeLists" :key="idx">
                <span>{{ item.companyName }}({{ item.companyCode }})</span>
              </el-form-item>
            </el-form>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column label="序列" width="50" type="index" />
      <el-table-column label="下单时间" width="110" prop="date" sortable :sort-by="['id']" />
      <el-table-column label="合同协议号" width="155" show-overflow-tooltip prop="contactNum" />
      <el-table-column label="品名" min-width="200" prop="regexpNodeList.productName" show-overflow-tooltip />
      <el-table-column label="目的国" width="105" prop="destCountry" sortable>
        <template v-slot="scope">{{ tableFormClass.transFormDestCountry(scope.row.destCountry) }}</template>
      </el-table-column>
      <el-table-column label="总金额(USD)" width="120" prop="regexpNodeList.totalAmount">
        <template v-slot="scope">$ {{ scope.row.regexpNodeList.totalAmount }}</template>
      </el-table-column>
      <el-table-column label="当前状态" width="101" prop="status" sortable>
        <template v-slot="scope"> {{ tableFormClass.transFormDestStatus(scope.row.status) }}</template>
      </el-table-column>
      <el-table-column label="操作" width="250">
        <template v-slot="props">
          <!-- key作为随机数，用途：及时更新数据 -->
          <ButtonSlots :propsDatas="props.row" :key="new Date().getTime()" />
        </template>
      </el-table-column>
      <template v-slot:append>
        <p v-if="customsDocsDataBus.loadMoreSubPage.loading" class="loadingFont">
          {{ customsDocsDataBus.loadMoreSubPage.loadingText }}
        </p>
      </template>
    </el-table>
  </div>
  <!-- 弹出详情框 -->
  <DialogDetail v-if="maskDivState.status" :maskDivStateFather="maskDivState" />
  <!-- 弹出支付框 -->
  <DialogQrCode v-if="maskDivState.payStatus" :maskDivStateFather="maskDivState" />

  <!-- 弹出添加电子授权委托 -->
  <div class="dialogbox">
    <el-dialog v-model="customsDocsDataBus.dialogFormVisible" title="电子委托授权"
      @close="tableFormClass.closeFunc(authorizeRef)" width="600px">
      <el-form :model="customsDocsDataBus.dialogFormData" :rules="dialogRules" ref="authorizeRef">
        <el-table :data="customsDocsDataBus.dialogFormData.dialogData" style="width: 100%" max-height="250"
          class="dialogBoxTable">
          <el-table-column prop="companyName" label="报关行名称" width="340px">
            <template v-slot="scope">
              <el-form-item v-if="!scope.row.optionsCode" class="mb-0" prop="companyName"
                :error="inputErrorMsg.companyName">
                <el-input v-model="scope.row.companyName"
                  @blur="tableFormClass.toUpwordFunc(scope.row.companyName, 'companyName')" size="small" />
              </el-form-item>
              <span v-else> {{ scope.row.companyName }}</span>
            </template>
          </el-table-column>

          <el-table-column prop="companyCode" label="十位海关注册编码" width="160px">
            <template v-slot="scope">
              <el-form-item v-if="!scope.row.optionsCode" class="mb-0" prop="companyCode"
                :error="inputErrorMsg.companyCode">
                <el-input v-model="scope.row.companyCode"
                  @blur="tableFormClass.toUpwordFunc(scope.row.companyCode, 'companyCode')" size="small" />
              </el-form-item>
              <span v-else> {{ scope.row.companyCode }}</span>
            </template>
          </el-table-column>

          <el-table-column prop="optionsCode" label="操作" width="80px">
            <template v-slot="scope">
              <el-link type="primary" size="small" @click.prevent="
                tableFormClass.operationRowFunc(scope.$index, scope.row.optionsCode, authorizeRef, inputErrorMsg)
                " :class="scope.row.optionsCode ? '' : 'optionsBtnClass'">
                {{ scope.row.optionsCode ? '移除' : '添加' }}
              </el-link>
            </template>
          </el-table-column>
        </el-table>
      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="customsDocsDataBus.dialogFormVisible = false">取消</el-button>
          <el-button type="primary"
            @click="tableFormClass.submitAuthorizeFunc(customsDocsDataBus.dialogFormData.dialogData, authorizeRef)">
            提交
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import {
  reactive, provide, ref, onDeactivated, onActivated, nextTick, inject, onBeforeMount,
} from 'vue';
// import { useRouter } from 'vue-router';
import { ElMessage } from 'element-plus';
import CustomsDocsClass from './class/customsdocsClass';
import ButtonSlots from './ButtonSlots.vue';
import DialogDetail from './DialogDetail.vue'; // 详情展示
import DialogQrCode from './DialogQrCode.vue'; // 二维码展示
import TableFormClass from './class/tableFormClass';
import failedReasonCode from '../../configs/failedReasonCode'; // 审核不通过错误代码

export default {
  name: 'tableform',
  components: {
    ButtonSlots,
    DialogDetail,
    DialogQrCode,
  },
  setup() {
    /**
       * 数据
       */
    // const router = useRouter();

    const tableFromRef = ref(null); // 数据内容REF
    const authorizeRef = ref(null); // 电子委托授权表单数据REF
    const customsDocsDataBus = inject('customsDocsDataBus'); // 数据（父组件共享）
    const setFilterOfContainer = inject('setFilterOfContainer'); // 数据（父组件共享）
    const inputErrorMsg = reactive({
      // 错误响应信息
      companyName: '',
      companyCode: '',
    });

    const dialogRules = reactive({
      companyName: [
        {
          validator: (rule, value, callback) => {
            const { dialogData } = customsDocsDataBus.dialogFormData;
            value = dialogData[dialogData.length - 1].companyName;
            if (value === '') {
              callback(new Error('必填项'));
            } else if (/[^\u4e00-\u9fa5()（）]+/.test(value)) {
              // 内容为汉字
              callback(new Error('内容限定为汉字'));
            } else {
              inputErrorMsg.companyName = '';
              callback();
            }
          },
          trigger: ['blur', 'change'],
        },
      ],
      companyCode: [
        {
          validator: (rule, value, callback) => {
            const { dialogData } = customsDocsDataBus.dialogFormData;
            value = dialogData[dialogData.length - 1].companyCode;
            if (value === '') {
              callback(new Error('必填项'));
            } else if (/([0-9a-zA-Z])\1{8,}/.test(value)) {
              // 限制出现重复次数大于8
              callback(new Error('输入内容不正确'));
            } else if (!/^[0-9a-zA-Z]{10}$/.test(value)) {
              callback(new Error('10位企业海关编码'));
            } else {
              inputErrorMsg.companyCode = '';
              callback();
            }
          },
          trigger: ['blur', 'change'],
        },
      ],
    });

    // 打开详情页、支付页参数（例如：遮罩)
    const maskDivState = reactive({
      status: false, // 详情遮罩开关
      options: {}, // 存放uuid等信息
      payStatus: false, // 支付遮罩开关
      closeWsFun: null, // 关闭webSocket函数
      newQrCodeFunc: null, // 生成新二维码地址
      updateDataFunc: null, // 更新完成后操作本地数据

      qrCodeURL: '', // 二维码数据
      createTime: '', // 创建二维码成功生成时间
      payment: 0, // 支付金额
      refQRCode: false, //  [true:取消关闭窗体，false:关闭窗体]
    });

    provide('maskDivState', maskDivState);

    const tableFormClass = new TableFormClass(tableFromRef, customsDocsDataBus);
    const customsDocsClass = new CustomsDocsClass(customsDocsDataBus, setFilterOfContainer);

    onBeforeMount(() => {
      setFilterOfContainer.loadCustomsdocsState = true;
    });

    // 切换为展示时
    onActivated(() => {
      const { loadCustomsdocsState } = setFilterOfContainer;

      // 编辑修改了数据返回时需要重新加载数据,同时在修改提交后的数据需要修改loadCustomsdocsState值
      if (loadCustomsdocsState) {
        try {
          // 加载时向后台服务器加载数据
          customsDocsClass.customsDocsAxiosFunc('initQueryFunc');
        } catch (error) {
          ElMessage.error(error.message);
        }
        setFilterOfContainer.loadCustomsdocsState = false;
      }
      nextTick(() => {
        // 进入当前页面重新设置table中的scrollTop值（注意：需将父元素设置指定高度方可生效)
        const { bodyWrapper } = tableFromRef.value.$refs;
        bodyWrapper.scrollTop = customsDocsDataBus.loadMoreSubPage.backupScrollTop;
      });

      tableFormClass.tableFromRef = tableFromRef; // 设置Ref
      tableFormClass.addListenScrollFunc(); // 添加滚动监听事件
    });

    // 切换为关闭时
    onDeactivated(() => {
      tableFormClass.tableFromRef = tableFromRef; // 设置Ref
      tableFormClass.removeListenScrollFunc(); // 移除滚动监听事件
    });

    return {
      failedReasonCode,
      tableFromRef,
      authorizeRef,
      tableFormClass,
      customsDocsDataBus,
      setFilterOfContainer,
      maskDivState,
      dialogRules,
      inputErrorMsg,
      // companyNameRules,
      // companyCodeRules,
    };
  },
};
</script>
<style lang="scss" scoped>
.tableform {
  padding: $base;
  height: 100% !important;
  overflow-y: scroll !important;
  -webkit-overflow-scrolling: touch !important;

  // 隐藏侧边滚动条
  ::-webkit-scrollbar {
    display: none;
    /* Chrome Safari */
  }

  -ms-overflow-style: none;
  /* IE 10+ */
  scrollbar-width: none;
  /* Firefox */

  :deep(.el-table) {
    border: 1px solid rgba($hovefontcolor, 0.5);
    border-radius: 5px;
    overflow: hidden;

    &:hover {
      border-color: $hovefontcolor;
    }
  }

  //下拉显示内容样式
  :deep(.el-table__cell.el-table__expanded-cell) {
    background-color: var(--el-color-primary-light-9);
    padding: $base;
    border: 1px solid var(--el-border-color-base);
    animation: 0.5s opacityClass 0s ease;
    -webkit-animation: 0.5s opacityClass 0s ease;
    -moz-animation: 0.5s opacityClass 0s ease;

    &:hover {
      border: 1px solid var(--el-border-color-hover);
      background-color: var(--el-color-primary-light-8) !important;
    }

    // }
    // :deep(.expanded + tr) {

    .el-form-item__label {
      color: $hovefontcolor;
      text-align: right;
      min-width: 80px;
      line-height: 30px;
    }

    .el-form-item__content {
      color: $dark;
      line-height: 30px;
    }
  }

  :deep(.el-table tr) {
    // background: #eee;
  }

  :deep(.el-table__body-wrapper) {
    // 定位table前设置父元素高度方可生效
    height: 336px;
  }

  :deep(.el-table__header) {
    tr th {
      &:last-child {
        text-align: center;
      }
    }
  }

  :deep(.el-table__body) {
    >tbody>tr>td {
      &:last-child {
        .cell {
          // text-align: center;
          // .slotbox {
          // 	background: red;
          // }
        }
      }
    }
  }

  @keyframes opacityClass {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes opacityClass {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes opacityClass {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  :deep(.el-table__header) {
    th {
      background-color: var(--el-color-primary-light-9);
      color: $dark;
    }
  }

  :deep(.el-table td.el-table__cell div) {
    white-space: pre;
  }

  .demo-table-expand {
    font-size: 0;
  }

  .demo-table-expand label {
    width: 90px;
    color: #99a9bf;
  }

  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
  }

  .loadingFont {
    width: 100%;
    margin: 5px;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    background: white;
  }

  .paied {
    color: red;
  }
}

:deep(.el-dialog__header) {
  border-bottom: 1px solid gray;
}

:deep(.el-dialog__body) {
  padding: 10px;
}

:deep(.el-dialog__body) {
  .el-table__row {
    >td {
      .cell {
        height: 52px;

        .el-form-item__error {
          padding-top: 0px;
        }

        span {
          line-height: 52px;
        }

        .optionsBtnClass {
          span {
            line-height: 42px;
          }
        }
      }
    }
  }
}
</style>
