<template>
  <div class="slotbox">
    <template v-for="item in globalPowerOptions" :key="item">
      <el-tooltip class="box-item" effect="dark" :content="item.tooltip" :show-after="500" placement="top-start">
        <el-link @click.stop="operationsBtnClass.getFunc(item.funcName, propsDatas, router)">{{
          item.title
          }}</el-link>
      </el-tooltip>
    </template>
  </div>

</template>
<script>
import {
  inject, toRefs, reactive,
} from 'vue';
import { useRouter } from 'vue-router';
import customsdocOpBtn from '../../configs/customsdocOpBtn';
import OperationsBtnClass from './class/operationsBtnClass';
import navgatTo from './hooks/navgatTo';

export default {
  name: 'slotbox',
  props: ['propsDatas'],
  setup(props) {
    const router = useRouter();
    const { mythods } = navgatTo();
    const maskDivState = inject('maskDivState');
    const customsDocsDataBus = inject('customsDocsDataBus'); // 存放数据
    const storeDatas = inject('storeDatas'); // 路由及tabs信息
    const setFilterOfContainer = inject('setFilterOfContainer');// 存放登陆信息
    // 根据不同status状态值展示不同按钮
    const globalPowerOptions = reactive({
      ...customsdocOpBtn.powerOptions[props.propsDatas.optionsStatus],
    });

    // 实例化当前函数类
    const operationsBtnClass = new OperationsBtnClass(
      customsDocsDataBus,
      setFilterOfContainer,
      maskDivState,
      storeDatas,
    );

    return {
      // dialogFormVisible,
      router,
      globalPowerOptions,
      operationsBtnClass,
      ...toRefs(mythods),
      ...toRefs(maskDivState),
    };
  },
};
</script>
<style lang="scss" scoped>
.slotbox {
  :deep(.el-link) {
    margin-right: 5px;

    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
