<template>
  <div class="customsdocs">
    <div class="mycardbox">
      <my-card>
        <template #mycard>
          <QueryForm />
        </template>
      </my-card>
    </div>
    <div class="mycardbox">
      <my-card>
        <template #mycard>
          <TableForm />
        </template>
      </my-card>
    </div>
  </div>
</template>

<script>
import { reactive, provide } from 'vue';
import MyCard from '../components/util/MyCard.vue';
import TableForm from '../components/customsdocs/TableForm.vue';
import QueryForm from '../components/customsdocs/QueryForm.vue';

export default {
  name: 'customsdocs',
  components: {
    MyCard,
    QueryForm,
    TableForm,
  },

  setup() {
    // TableForm子菜单数据
    const customsDocsDataBus = reactive({
      // queryFrom表单数据
      queryFormData: {
        contractNum: '', // 合同协议号
        destCountry: '', // 最终目的国
        companyName: '', // 发货人
        name: '', // 境外买家
        orderDate: [], // 下单时间
        status: '0', // 状态
        productName: '', // 产品名称
      },
      // 分页数据
      loadMoreSubPage: {
        rowNum: 10, // 每页加载条数
        loading: false, // 展示文本状态
        loadingText: '数据加载中...', // 展示文本状态内容
        backupScrollTop: 0, // 备份卷入值
        distance: 40, // 触发加载更多数据阈值
        overLimitDistance: false, // 超限阈值与否标识符
      },
      expandRowKey: [], // 目前的展开行数组值
      tableDatas: [], // 与tableData相同，只是装载的为向服务器加载的全部数据
      tableData: [], // 表单展示数据

      dialogFormVisible: false, // 电子授权委托添加信息对话框展示与否
      dialogFormData: {
        // 电子委托授权表格数据
        uuid: '', // 当前操作的id
        dialogData: [
          {
            companyName: '',
            companyCode: '',
            optionsCode: false, // true:移除，false：添加
          },
        ],
      },
    });

    const datas = reactive({});

    // TableForm子菜单数据
    provide('customsDocsDataBus', customsDocsDataBus);
    return {
      datas,
      customsDocsDataBus,
    };
  },
};
</script>
<style lang="scss" scoped>
  $viewHeight: calc(100vh - 100px - 20px);
  .customsdocs {
    height: $viewHeight;
    width: 100%;
    overflow-y: scroll !important;
    -webkit-overflow-scrolling: touch !important;

    .mycardbox {
      margin-bottom: 1px;
      &:last-child {
        .mycard {
          border-top-left-radius: 0 !important;
          border-top-right-radius: 0 !important;
          border-bottom-left-radius: $radiusSize !important;
          border-bottom-right-radius: $radiusSize !important;
        }
      }
      &:first-child {
        .mycard {
          border-top-left-radius: $radiusSize !important;
          border-top-right-radius: $radiusSize !important;
          border-bottom-left-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
        }
      }
    }
    .mycard {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      overflow: hidden;
    }
  }
</style>
