<template>
  <div class="queryForm">
    <el-form :model="customsDocsDataBus.queryFormData" label-width="105px" :inline="false" size="normal"
      :rules="queryFormRules" ref="queryFormRef" @submit.prevent>
      <el-form-item label="合同协议号:" prop="contractNum">
        <el-input @input="(value) => queryFormClass.inputFunc(value)"
          v-model="customsDocsDataBus.queryFormData.contractNum" placeholder="请输入合同协议号" size="small" clearable />
      </el-form-item>
      <el-form-item label="最终目的国:" prop="destCountry">
        <el-select v-model="customsDocsDataBus.queryFormData.destCountry" id="destCountry" size="small" filterable
          default-first-option @input="queryFormClass.inputFunc($event, 'destCountry')"
          @change="(value) => queryFormClass.optionChangeFuncs(value, 'destCountry')"
          @visible-change="queryFormClass.vsbChangeOptions($event, 'destCountry', country)" placeholder="请选择要查询最终目的国">
          <el-option v-for="option in activeDatas.destCountry" :key="option.value" :label="option.tagName"
            :value="option.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="境内发货人:" prop="companyName">
        <el-input v-model="customsDocsDataBus.queryFormData.companyName" placeholder="请输入境内发货人" size="small"
          clearable />
      </el-form-item>
      <el-form-item label="境外买家:" prop="name">
        <el-input v-model="customsDocsDataBus.queryFormData.name" placeholder="请输入境外买家" size="small" clearable />
      </el-form-item>
      <el-form-item label="下单时间:" prop="orderDate">
        <div class="orderTime">
          <el-date-picker size="small" v-model="customsDocsDataBus.queryFormData.orderDate" type="daterange"
            start-placeholder="开始日期" end-placeholder="结束日期" :default-time="activeDatas.defaultTimeFunc"
            :disabled-date="queryFormClass.disabledDateFunc" @blur="queryFormClass.blurFun" format="YYYY-MM-DD"
            unlink-panels value-format="x" :clearable="false"></el-date-picker>
        </div>
      </el-form-item>
      <el-form-item label="状态:" prop="status">
        <el-select v-model="customsDocsDataBus.queryFormData.status" size="small" id="status" filterable
          default-first-option @change="(value) => queryFormClass.optionChangeFuncs(value, 'status')"
          @visible-change="queryFormClass.vsbChangeOptions($event, 'status', stepStatus.stepStatusAry)"
          @input="queryFormClass.inputFunc($event, 'status')" placeholder="请选择当前状态">
          <el-option v-for="item in activeDatas.status" :key="item.value" :label="item.tagName" :value="item.value"
            :disabled="item.disabled"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="产品名称:" prop="productName">
        <el-input v-model="customsDocsDataBus.queryFormData.productName" placeholder="输入所需查询产品名称" size="small"
          clearable />
      </el-form-item>
      <el-form-item>
        <el-row>
          <el-button-group>
            <el-button type="success" @click="queryFormClass.searchSubmitFunc(queryFormRef)"
              native-type="submit">查询</el-button>
            <el-button type="warning" @click="queryFormClass.resetContentFunc(queryFormRef)">重置</el-button>
            <el-button type="primary" @click="queryFormClass.newOrderFunc">创建订单</el-button>
          </el-button-group>
        </el-row>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import {
  inject, onBeforeMount, reactive, ref, watch,
} from 'vue';
import { useRouter } from 'vue-router';
import QueryFormClass from './class/queryFormClass';
import country from '../../configs/country'; // 目的地国家数组
import stepStatus from '../../configs/stepStatusAry'; // 当前进度代码

// import Rules from '../../utils/utilsClass/rulesClass';

export default {
  name: 'queryForm',
  setup() {
    const queryFormRef = ref(null);
    const router = useRouter();
    const storeDatas = inject('storeDatas');
    const setFilterOfContainer = inject('setFilterOfContainer'); // 登陆信息数据
    // 状态值
    const activeDatas = reactive({
      status: [], // 状态，根据登陆与否来制作options列表的显示
      destCountry: [...country], // 最终目的国
      defaultTimeFunc: [
        // 起始与结束时刻
        new Date(new Date().getFullYear(), new Date().getMonth() - 1, new Date().getDate(), 0, 0, 0),
        new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59),
      ],
    });
    const verifyCod = {
      required: false,
      asyncValidator: (rule, value) => new Promise((resolve, reject) => {
        if (verifyCod.required && value === '') {
          reject(new Error('必填项'));
        } else if (value !== '' && value.length < 2) {
          reject(new Error('输入内容不得低于2位'));
        } else if (value !== '' && value.length > 120) {
          reject(new Error('超出长度范围值'));
        } else if (/["'\\?^？|￥$^@!~`>》《<*；;]+/.test(value)) {
          reject(new Error('请务输入特殊符号'));
        } else {
          resolve(value);
        }
      }),
      trigger: ['blur'],
    };
    // 验证信息
    const queryFormRules = reactive({
      productName: [verifyCod], // 产品名称
      contractNum: [verifyCod], // 合同协议号
      companyName: [verifyCod], // 境内发货人
      name: [verifyCod], // 境外买家
    });

    // // 表单数据(移到customsdocs.vue作为共享数据)
    // const formData = reactive({
    //   contractNum: '', // 合同协议号
    //   destCountry: '', // 最终目的国
    //   companyName: '', // 发货人
    //   name: '', // 境外买家
    //   orderDate: [], // 下单时间
    //   status: '0', // 状态
    //   // 分页
    //   pageNum: 0, // 加载页
    //   rowNum: 20, // 每页加载条数
    // });
    const customsDocsDataBus = inject('customsDocsDataBus');

    const queryFormClass = new QueryFormClass(
      customsDocsDataBus,
      activeDatas,
      storeDatas,
      router,
      setFilterOfContainer,
    );
    const keepAliveLists = inject('keepAliveLists'); // tab按钮数据

    // 监听登陆信息
    const whIslogined = watch(
      () => [setFilterOfContainer.isLogin, setFilterOfContainer.userNickname],
      ([newIsLogin]) => {
        // 设置状态下拉列表可编辑状态
        queryFormClass.setStatusFunc(newIsLogin);
        //  后向后台请求数据
        if (keepAliveLists.includes('customsdocs')) {
          // 登陆后且tab中有customsDocs页才执行，重新向服务器拉取数据
          queryFormClass.searchSubmitFunc(queryFormRef.value);
        } else {
          whIslogined(); // 停止watch执行
        }
      },
    );

    onBeforeMount(() => {
      queryFormClass.setStatusFunc(setFilterOfContainer.isLogin); // form组件「状态」是否可编辑
      queryFormClass.setPickerDefaultDate(); // 设置下单时间初始值
    });
    return {
      whIslogined,
      setFilterOfContainer,
      customsDocsDataBus,
      queryFormRef,
      queryFormClass,
      activeDatas,
      country,
      stepStatus,
      router,
      storeDatas,
      queryFormRules,
    };
  },
};
</script>
<style lang="scss" scoped>
.mycard {
  padding: $base * 0.5 !important;
}

// 错误提示信息向上提升
:deep(.el-form-item__error) {
  padding-top: 0px;
}

.queryForm {
  padding: $base $base $base * 0.3 $base;

  :deep(.el-form) {
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 1150px) {
      .el-form-item {
        width: 50% !important;

        &:nth-child(2n) {
          .el-form-item__content {
            margin-right: 0 !important;
          }
        }

        &:nth-last-child(2) {
          width: 50%;
        }

        .el-form-item__content {
          margin-right: 15px !important;
        }
      }
    }

    .el-form-item {
      margin-bottom: 10px !important;
      width: 33.33%;

      &:nth-last-child(2) {
        width: 66.66%;
      }

      &:last-child {
        .el-form-item__content {
          margin-right: 0 !important;
        }

        .el-row>.el-button-group {
          width: 100%;

          >button {
            // 	display: flex;
            // 	flex-wrap: nowrap;
            // 	justify-content: flex-end;
            width: 33.33%;
          }
        }
      }

      .el-form-item__content {
        margin-right: 15px;
      }

      &:nth-child(3n) {
        .el-form-item__content {
          margin-right: 0;
        }
      }

      .el-select {
        width: 100%;
      }
    }
  }

  .orderTime {
    width: 100%;

    :deep(.el-date-editor) {
      width: 100%;
    }
  }
}
</style>
