// dialog.js 封装element的弹出提示框
import { ElMessageBox, ElMessage } from 'element-plus';

function diaLog(boxTitle, boxMessage, { confrimMessage, cancelMessage }, confirmButtonText, cancelButtonText) {
  return new Promise((resolve) => {
    ElMessageBox.confirm(
      boxMessage,
      boxTitle,
      {
        confirmButtonText: confirmButtonText || '确定',
        cancelButtonText: cancelButtonText || '取消',
        type: 'warning',
        closeOnClickModal: false, // 点击蒙层取消
        closeOnPressEscape: false, // 禁止按ESC
      },
    ).then((r) => {
      if (confrimMessage) {
        ElMessage({
          type: 'warning',
          message: confrimMessage,
        });
      }
      resolve(r);
    }).catch((err) => {
      if (cancelMessage) {
        ElMessage({
          type: 'success',
          message: cancelMessage,
        });
      }
      resolve(err);
    });
  });
}
function diaLogOnlyConfrim(boxTitle, boxMessage, confirmMessage) {
  return new Promise((resolve) => {
    ElMessageBox.alert(boxMessage, boxTitle, {
      confirmButtonText: '确定',
      callback: (action) => {
        if (action === 'confirm') {
          if (confirmMessage) {
            ElMessage({
              type: 'info',
              message: confirmMessage,
            });
          }
          resolve();
        }
      },
    });
  });
}
export default { diaLog, diaLogOnlyConfrim };
