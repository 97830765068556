import { ElMessage, ElMessageBox } from 'element-plus';
import constClass from '../../../store/class/constClass'; // 定义的所需权限描述
import myAxios from '../../../utils/axios/index_v2';
import TabClkClass from '../../tabslist/class/tabClkClass';

export default class CustomsDocsClass {
  /**
   *
   * @param {*} customsDocsDataBus 共享数据
   * @param {*} setFilterOfContainer 登陆信息
   */
  constructor(customsDocsDataBus, setFilterOfContainer) {
    this.customsDocsDataBus = customsDocsDataBus;
    this.setFilterOfContainer = setFilterOfContainer;
    this.optUuid = ''; // 操作的产品UUID
    this.optDatabase = ''; // 将要操作后台的哪个数据[rds,tempSubmit,submit]
  }

  // initQueryFunc 向服务端获取查询数据成功后处理函数
  initQueryFuncOfOkay = (res) => {
    // 初始加载数据
    if (res.result !== null) {
      // 修改超级管理用户的操作按钮
      res.result.map((val) => {
        // 只有超级管理员才可以不用付款即可下载
        val.optionsStatus = this.setFilterOfContainer.visitAuth === constClass.USER_ADMIN
          ? this.setFilterOfContainer.visitAuth // 超级用户
          : val.status; // 正常用户
        if (val.data !== '') {
          // UTC时间转换成当地时间
          const rs = new Date(val.date.replaceAll('-', '/').replaceAll('UTC', ''));
          val.date = `${rs.getFullYear()}-${rs.getMonth() + 1}-${rs.getDate()}`;
        }
        return val;
      });
      this.customsDocsDataBus.tableDatas = res.result; // 全部数据
      const { tableDatas, loadMoreSubPage } = this.customsDocsDataBus;
      // 根据索取数量向展示tableData添加数据
      // delete this.customsDocsDataBus.tableData;
      this.customsDocsDataBus.tableData = [];
      this.customsDocsDataBus.tableData.push(...tableDatas.slice(0, loadMoreSubPage.rowNum));
      loadMoreSubPage.loading = false;
    } else {
      // 没有数据
      this.resetLoadDataFunc(); // 初始化存储服务器数据的数据
    }
  };

  /** deleteFunc 向服务器请求 删除内容成功后处理函数
   *
   * @param {*} res // 向后台操作删除服务结果
   */
  deleteFuncOfOkay = (res) => {
    const { tableDatas, tableData } = this.customsDocsDataBus;
    if (res.message !== '删除成功') {
      ElMessage.error('删除失败');
      return;
    }
    // 执行清除总表与展示表中的数据（总表的idx与展示表的idx相同)
    tableDatas.map((itemObj, idx) => {
      if (itemObj.id === this.optUuid) {
        tableDatas.splice(idx, 1); // 删除总数据中的内容
        if (tableData[idx] !== undefined) {
          // 删除展示区的内容
          tableData.splice(idx, 1);
          if (tableData.length === 0) {
            // 无展示数据
            this.resetLoadDataFunc(); // 清空数据行
          }
        }
      }
      return tableDatas;
    });
  };

  // resetLoadDataFunc 初始化存储服务器数据的数据
  resetLoadDataFunc = () => {
    this.customsDocsDataBus.tableDatas = []; // 清空全部数据
    this.customsDocsDataBus.tableData = []; // 清空展示数据
  };

  /**
   * 从"查询制作文件"点击编辑至"录入报关信息"页函数
   * @param {object} storeDatas 存储数据
   * @param {object} router 路由信息
   * @param {*} fromBaseData 表单数据
   * @param {*} uuid 唯一识别号
   * @returns 无数据
   */
  navigatToCreateCustoms = (storeDatas, router, fromBaseData, uuid) => {
    // 先判断"录入信息"是否在编辑中
    const { tabsDataBus } = storeDatas;
    if (Object.prototype.hasOwnProperty.call(storeDatas.tabsDataBus, '/createcustoms')) {
      const tabClkClass = new TabClkClass(storeDatas, tabsDataBus['/createcustoms'], router);
      const { query } = storeDatas.tabsDataBus['/createcustoms'];
      if (Object.prototype.hasOwnProperty.call(query, 'params')) {
        // 存在params属性
        const paramsObj = JSON.parse(decodeURIComponent(window.atob(query.params)));// 解析uuid
        if (Object.prototype.hasOwnProperty.call(paramsObj, 'uuid') && paramsObj.uuid === uuid) {
          // 判断当前【录入报关信息】编辑页的uuid与要进入的uuid是否相同
          tabClkClass.tabDatas = storeDatas.tabsDataBus['/createcustoms'];
          tabClkClass.tabClkFunc();
          return;
        }
      }
      // 正在编辑
      ElMessageBox.confirm('[录入报关信息]正处于编辑中,数据尚未保存,是否跳转至编辑页保存数据?', {
        title: '提示',
        type: 'warning',
        showClose: false,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false, // 点击蒙层取消
        closeOnPressEscape: false, // 禁止按ESC
      })
        .then((res) => {
          if (res === 'confirm') {
            tabClkClass.tabDatas = storeDatas.tabsDataBus['/createcustoms'];
            tabClkClass.tabClkFunc();
          }
        })
        .catch((err) => err);
    } else {
      let db = '';
      let cuuid = '';
      // 不同类别数字代表当前状态值
      switch (fromBaseData) {
        case 'rds': // 未登陆|存于rds|编辑操作
          db = '0';
          break;
        case 'tempSubmit': // 登陆|暂存|存于sql.operation|编辑操作
          db = '1';
          break;
        case 'submit': // 登陆|提交|存于sql.trade|编辑操作
          db = '2';
          break;
        case 'copy': // 登陆|提交|存于sql.trade|复制操作
          cuuid = uuid;// 被复制uuid
          uuid = `${new Date().valueOf()}${((Math.random() * 0x1000) | 0).toString().padStart(4, 0)}`;// 例为空，beforeEach会自动添加uuid
          db = '3';
          break;
        default: // 其他
          db = '4';
          break;
      }
      // 这里没有填写UUID，是为了在路由跳转时检测UUID，无则重新向tabsDataBus添加路由
      router
        .replace({
          path: '/createcustoms',
          query: {
            params: window.btoa(encodeURIComponent(JSON.stringify(
              { uuid, db, cuuid },
            ))),
          }, // 参数
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  /** customsDocsAxiosFunc customsdocs页向后台加载数据
   *
   * @param {*} activeModeType 执行的类型（要求与后台服务参数一致）
   */
  customsDocsAxiosFunc = (activeModeType) => {
    if (
      !['initQueryFunc', 'editFunc', 'deleteFunc', 'copyFunc', 'payFunc'].includes(activeModeType)
    ) {
      throw new Error('请求参数错误');
    }
    const that = this;
    myAxios()
      .initAxios({
        method: 'POST',
        url: '/customs_docs',
        Headers: { Accept: 'application/json; charset=utf-8' },
        data: {
          ...this.customsDocsDataBus.queryFormData, // 表单条件数据
          activeModeType, // 执行的类型['initQueryFunc', 'editFunc', 'deleteFunc', 'copyFunc', 'payFunc']
          optUuid: this.optUuid, // 操作当前id
          optDatabase: this.optDatabase, // 将要操作后台的哪个数据[rds,tempSubmit,submit]
        }, // 查询数据
      })
      .then((res) => {
        if (res.code === 200) {
          switch (
          activeModeType // 查询结果处理
          ) {
            case 'initQueryFunc':
              that.initQueryFuncOfOkay(res);
              break;
            case 'deleteFunc': // 删除结果处理
              that.deleteFuncOfOkay(res);
              break;
            default:
              break;
          }
        }
        ElMessage.success(res.message);
      })
      .catch((err) => {
        ElMessage.error('查询数据失败', err.message);
      });
  };
}
